<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      :is-edit="true"
      :fields="fields"
      :row-data="row_data"
      post-api="/admin/UpdateAdmin"
      finish-router="admin"
      permission="Admin_Edit"
    >
    </edit-field>
  </page-content>
</template>

<script>
import fields from '@/views/admin/fields'
import EditField from '@/components/EditField'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import PageContent from '@/components/PageContent'

export default {
  name: 'Edit',
  components: {
    PageContent,
    EditFieldHeader,
    EditField,
    fields
  },
  data() {
    return {
      title: common.getMenuName('admin') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      row_data: {}
    }
  },
  mounted() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.push({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/admin/GetAdmin?adminID=' + this.id)
      .then(res => {
        this.row_data = res.data.admin
      })
  }
}
</script>

<style scoped>

</style>
